import React from "react"

const SubHero = ({ title, page }) => {
  return (
    <>
      <section className="bg-half bg-light d-table w-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="page-next-level">
                <h4 className="title">{title}</h4>
                <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                  <li>
                    <a
                      href="/"
                      className="text-uppercase font-weight-bold text-dark"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/${page}`}
                      className="text-uppercase font-weight-bold text-dark"
                    >
                      {page}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
    </>
  )
}

export default SubHero
