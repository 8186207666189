import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../containers/layout"

import Team from "../components/team"
import SubHero from "../components/subHero"

const TeamPage = () => (
  <>
    <Helmet>
      <link href="/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
    </Helmet>
    <Layout>
      <SubHero title="Our Pilots" page="team" />
      <Team />
    </Layout>
  </>
)

export default TeamPage
