import React from "react"
import dave from "../images/team-dave.jpg"
import rochelle from "../images/team-rochelle.jpg"
import pete from "../images/team-pete.jpg"

const Team = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <p className="mb-4">Introducing...</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 mt-4 pt-2">
            <div className="team text-center">
              <div className="position-relative">
                <div
                  style={{ backgroundImage: `url(${pete})` }}
                  className="img-fluid avatar avatar-ex-large rounded-pill shadow team-img"
                />
              </div>
              <div className="content pt-3 pb-3">
                <h5 className="mb-0">
                  <p className="name text-dark">
                    Peter Kirkham
                  </p>
                </h5>
                <small className="designation text-muted">
                  Head of Development
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 pt-2">
            <div className="team text-center">
              <div className="position-relative">
                <div
                  style={{ backgroundImage: `url(${rochelle})` }}
                  className="img-fluid avatar avatar-ex-large rounded-pill shadow team-img"
                />
              </div>
              <div className="content pt-3 pb-3">
                <h5 className="mb-0">
                  <p className="name text-dark">
                    Rochelle Greenberg
                  </p>
                </h5>
                <small className="designation text-muted">Head of Design</small>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 pt-2">
            <div className="team text-center">
              <div className="position-relative">
                <div
                  style={{ backgroundImage: `url(${dave})` }}
                  className="img-fluid avatar avatar-ex-large rounded-pill shadow team-img"
                />
              </div>
              <div className="content pt-3 pb-3">
                <h5 className="mb-0">
                  <p className="name text-dark">
                    David Matthews
                  </p>
                </h5>
                <small className="designation text-muted">
                  Head of Marketing
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
